import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {environment} from '@Env/environment';
import {HttpClient} from '@angular/common/http';
import {CandidatureAter} from '@Common/apps/ater/services/candidature/candidature.model';
import {finalize} from "rxjs/operators";
import * as FileSaver from 'file-saver';

/**
 * Service pour la gestion côté front-office des candidatures
 */
@Injectable({
  providedIn: 'root'
})
export class CandidatureAterFOService extends DaoGeneric<CandidatureAter> {
  private url = `${environment.baseUrl}/ater/candidaturefo/`;
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }
  /**
   * Est-ce qu'il est trop tard pour candidater ?
   * cas date fin avec ou sans heure de fin TG-349
   * @param candidature
   */
  isTooLate(candidature: CandidatureAter) {
    const _currentDate = new Date();
    let _endDateStr = `${candidature.offre_obj.campagne_obj.date_fin}`;
    if (candidature.offre_obj.campagne_obj.heure_fin_candidatures) {
      _endDateStr = `${_endDateStr}T${candidature.offre_obj.campagne_obj.heure_fin_candidatures}`;
    } else {
      _endDateStr = `${_endDateStr}T23:59:59`;
    }
    const _untilEnd = new Date(_endDateStr);
    return _untilEnd <= _currentDate;
  }

  patchArticle(candidature: CandidatureAter) {
    const url = `${this.url}${candidature.uuid}/switch_article/`;
    return this.httpClient.patch<any>(url, candidature);
  }

  patchSeniority(candidature: CandidatureAter) {
    const url = `${this.url}${candidature.uuid}/switch_seniority/`;
    return this.httpClient.patch<any>(url, candidature);
  }

  downloadAcceptationLetter(candidature: CandidatureAter) {
    const url = `${this.url}${candidature.uuid}/acceptation_letter/`;
    return this.httpClient.get(url, { params: {}, responseType: 'blob'  });
  }

  downloadAcceptationLetterWithSub(candidature: CandidatureAter, subSink, pubSubSvc = null) {
    pubSubSvc?.publish('loading', true);
    const filename = `${candidature.offre_obj.numero}-${candidature.candidat_obj?.last_name || 'last-name'}-${candidature.candidat_obj?.first_name || 'first-name'}`;
    subSink.sink = this.downloadAcceptationLetter(candidature)
      .pipe(finalize(() => pubSubSvc?.publish('loading', false)))
      .subscribe(
        (v) => {
          FileSaver.saveAs(v, filename);
        }
      );
  }
}
