import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActionDataTable, DataTableComponent, DataTableHeaderComponentService} from '@Common/widgets/general/data-table';
import {Router} from '@angular/router';
import {
  CandidatureAter,
  RangStatut,
  StatusCandidateAter
} from '@Common/apps/ater/services/candidature/candidature.model';
import {CandidatureAterFOService} from '@Common/apps/ater/services/candidature/candidature-fo.service';
import {CandidatureTable} from 'app/ater-candidatures/helper/candidatureTable';
import {saveAs} from 'file-saver';
import {SubSink} from '@Common/core/utils/subsink';
import {ModelPjAterService} from '@Common/apps/ater/services/models-pj/model-pj-file.service';
import * as dateFnsAdaptor from 'date-fns';
import {fr} from 'date-fns/locale';
import {ModelPjType} from '@Common/apps/ater/services/models-pj/model-pj.model';
import {PubSubService} from "@Common/core/services";

@Component({
  selector: 'app-candidatures',
  templateUrl: './candidatures.component.html',
  styleUrls: ['./candidatures.component.scss']
})
export class CandidaturesComponent extends CandidatureTable implements OnInit, OnDestroy {
  subSink = new SubSink();
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;
  modelsExist = false;
  actions: ActionDataTable[] = [
    {
      label: 'Visualiser', tooltip: 'Visualiser cette candidature', icon: 'remove_red_eye',
      click: (element: CandidatureAter) => this._viewCandidature(element),
      iconcolor: 'custom-second',
    },
    {
      label: 'Editer', tooltip: 'Edite cette candidature', icon: 'edit',
      click: (element: CandidatureAter) => this._editCandidature(element),
      hidden: (element: CandidatureAter) => !this._canEdit(element),
      iconcolor: 'custom-fourth',
    },
    {
      label: 'Download-letter', tooltip: 'Télécharger la lettre d\'acceptation type', icon: 'download',
      click: (element: CandidatureAter) => this._downloadAcceptationLetter(element),
      hidden: (element: CandidatureAter) => !this._canDownloadLetter(element),
      iconcolor: 'custom-fourth',
    },
  ];

  constructor(private router: Router,
              protected dataTableHeaderSvc: DataTableHeaderComponentService,
              private candidatureFOSvc: CandidatureAterFOService,
              private pjSvc: ModelPjAterService,
              private pubSubSvc: PubSubService,) {
    super(dataTableHeaderSvc);
    this.dsCandidatures.daoService = this.candidatureFOSvc;
  }

  ngOnInit(): void {
    this._setCellStatus();
    this.subSink.sink = this.pjSvc
      .isModelsExist(ModelPjType.candidat)
      .subscribe(_data => this.modelsExist = _data['is_exist']);
  }

  exportZipModelCandidats() {
    this.subSink.sink = this.pjSvc
      .getModelPjZip(ModelPjType.candidat)
      .subscribe((data: Blob) => {
        const fileName = `Modeles-fichiers-types-candidat-${this.formatDateForZip()}.zip`;
        saveAs(data, fileName);
      });
  }
  formatDateForZip() {
    return dateFnsAdaptor.format(new Date(), 'yyyy-MM-dd-HH-mm', { locale: fr });
  }
  private _editCandidature(candidature: CandidatureAter) {
    this.router.navigate(['/candidature/candidater', candidature.uuid], {});
  }

  /**
   * Une candidature est éditable uniquement
   * - si brouillon
   * - si incomplète
   *
   * TODO : voir le reste des règles de gestion : quid campagne et/ou dates campagne
   * @private
   */
  private _canEdit(candidature: CandidatureAter): boolean {
    const tooLate = this.candidatureFOSvc.isTooLate(candidature);
    return [StatusCandidateAter.draft, StatusCandidateAter.incomplete].includes(candidature.statut) && !tooLate;
  }

  private _viewCandidature(candidature: CandidatureAter): void {
    this.router.navigate(['candidature/visualize', candidature.uuid], {});
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  private _canDownloadLetter(candidature: CandidatureAter) {
    return (candidature.offre_obj.laureat === candidature.id && candidature.rang_statut === RangStatut.waiting)
  }
  private _downloadAcceptationLetter(candidature: CandidatureAter): void {
    this.candidatureFOSvc.downloadAcceptationLetterWithSub(candidature, this.subSink, this.pubSubSvc);
  }
}
